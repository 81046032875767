import "./styles.css";
import MenuTable from "./MenuTable";
import logo from "./assets/logo.png";

export default function App() {
  return (
    <div className="container">
      <div className="border right"></div>
      <div className="menu">
        <div className="logo">
          <img src={logo} />
        </div>
        <MenuTable />
      </div>
      <div className="border left"></div>
    </div>
  );
}
