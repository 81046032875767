import React, { useState } from "react";
import foods from "./foods";

export default function MenuTable(): React.ReactElement {
  const [selected, setSelected] = useState<number>(1);
  const selectedClassName = "selected";
  return (
    <>
      <ul>
        {foods.map((item: any) => {
          return (
            <li
              className={selected === item.key ? selectedClassName : ""}
              onClick={() => setSelected(item.key)}
            >
              {item.label}
            </li>
          );
        })}
      </ul>
      <div className="content">
        {
          selected === 5 ? <div style={{margin: "4em 0", textAlign: 'center'}}>انواع نوشابه، دوغ، دلستر و لیموناد موجود است.</div> :
        foods
          .find((el: any) => el.key === selected)
          ?.items?.map((item: {name: string, price: number, description: string}) => {
            return (
              <div className="item">
                <div className="item-details">
                  <p className="name">{item.name}</p>
                  <p className="price">{selected === 4 ? item.price : item.price + 10 + 20}</p>
                </div>
                <div className="description">
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })
          }
        <div className="telephones">
          <div onClick={() => window.location.href = "tel:02122132042"}>22132042</div>
          <div onClick={() => window.location.href = "tel:02122137701"}>22137701</div>
        </div>
        <div className="additional-descriptions">
          <div>
            ارسال رایگان محدوده سعادت آباد و شهرک غرب
            <br /> فقط به صورت تلــــــفنی
          </div>
        </div>
      </div>
    </>
  );
}
