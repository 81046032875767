import kebabs from "./foodsData/kebabs.json";
import irani from "./foodsData/irani.json";
import khorak from "./foodsData/khorak.json";
import pishghaza from "./foodsData/pishghaza.json";
const foods: any[] = [
  {
    label: "کباب‌ها",
    key: 1,
    items: kebabs,
  },
  {
    label: "غذای ایرانی",
    key: 2,
    items: irani,
  },
  {
    label: "خوراک‌ها",
    key: 3,
    items: khorak,
  },
  {
    label: "پیش‌غذا و سالاد",
    key: 4,
    items: pishghaza,
  },
  {
    label: "نوشیدنی",
    key: 5,
  },
];
export default foods;
